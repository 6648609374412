import React, { useEffect, useState } from "react";
// import axios from "axios";
import { Button, SelectInput, TextInput, Checkbox } from "../../../components";
import {
  updateDoc,
  doc,
  // query,
  // where,
  onSnapshot,
  // getDoc,
} from "firebase/firestore";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  participantsCollectionRef,
  auth,
  db,
} from "../../../config/firebaseConfig";
import {
  allCities,
  allCountries,
  allStates,
} from "../../../constant/countryStateCityList";
import { deleteObject } from "firebase/storage";
// import { registerSuccessEmail } from "../../../constant/emailTemplates";
// import PaymentSuccess from "./PaymentSuccess";
import ImageCapture from "./ImageCapture";
import FileUpload from "./FileUpload";
import { useAuthState } from "react-firebase-hooks/auth";
// import envConfig from "../../../config/envConfig";

const Step3 = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const searchQuery = useSearchParams()[0];
  const referenceNumber = searchQuery.get("ref");

  const [participants, setParticipants] = useState([]);
  const [participantIds, setParticipantIds] = useState([]);
  const [participantArray, setParticipantArray] = useState([]);
  const [error, setError] = useState("");
  // const [paymentRef, setPaymentRef] = useState();

  const [activeParticipantIndex, setActiveParticipantIndex] = useState(0);
  const [userAuth] = useAuthState(auth);
  const [checked, setChecked] = useState(false);

  //fetch participants for the current user
  useEffect(() => {
    const fetchParticipants = async () => {
      // fetch form local storage
      const ids = JSON.parse(
        localStorage.getItem("practido-step2-participantIds")
      );
      setParticipantIds(ids);
      ids?.forEach((id) => {
        const studentDocRef = doc(db, "participants", id);
        onSnapshot(studentDocRef, (document) => {
          if (!document.exists()) {
            console.log("No student document!");
          }
          setParticipants((prev) => [...prev, { ...document.data(), id }]);
        });
      });
    };
    userAuth && fetchParticipants();
  }, [userAuth]);

  useEffect(() => {
    let arr = [];
    for (let i = 1; i <= participants.length; i++) {
      let obj = {};
      arr.push(obj);
    }
    setParticipantArray(arr);
  }, [participants]);

  const [imageCaptureModule, setImageCaptureModule] = useState(false);
  const [participantModuleIndex, setParticipantModuleIndex] = useState(0);
  const [uploadImage, setUploadImage] = useState(null);
  const [imageUploadProgress, setImageUploadProgress] = useState(0);
  const [imageUploading, setImageUploading] = useState(false);
  const [idProofUploading, setIdProofUploading] = useState(false);

  const openImageCapture = (index, type) => {
    setImageCaptureModule(type);
    setParticipantModuleIndex(index);
  };

  useEffect(() => {
    let interval;
    if (imageUploading) {
      // Start the progress bar when loading is true
      interval = setInterval(() => {
        setImageUploadProgress((prevProgress) => (prevProgress + 5) % 101);
      }, 100);
    } else {
      setImageUploadProgress(0);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [imageUploading]);

  const updateUrl = (file, url, index) => {
    const newParticipants = [...participantArray];
    if (file === "idProof") {
      newParticipants[index].idProofUrl = url;
    } else {
      newParticipants[index].imageUrl = url;
    }
    setParticipantArray(newParticipants);
  };
  const updateRef = (file, ref, index) => {
    const newParticipants = [...participantArray];
    if (file === "idProof") {
      newParticipants[index].idProofRef = ref;
    } else {
      newParticipants[index].imageRef = ref;
    }
    setParticipantArray(newParticipants);
  };

  const deleteFile = async (fileRef) => {
    try {
      await deleteObject(fileRef);
    } catch (error) {
      throw error;
    }
  };

  const handleDelete = async (file, index) => {
    const fileRef =
      file === "idProof"
        ? participantArray[index].idProofRef
        : participantArray[index].imageRef;
    try {
      await deleteFile(fileRef);
      updateUrl(file, "", index);
      updateRef(file, "", index);
    } catch (error) {
      console.log(error);
    }
  };
  const handleRetake = async (index) => {
    const fileRef = participantArray[index].imageRef;
    try {
      await deleteFile(fileRef);
      updateUrl("participantImage", "", index);
      updateRef("participantImage", "", index);
      setImageCaptureModule(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFieldChange = (fieldValue, index, fieldName) => {
    const newParticipants = [...participantArray];
    newParticipants[index][fieldName] = fieldValue;
    setParticipantArray(newParticipants);
  };

  const updateParticipantDetails = async (participant, index) => {
    let participantData = {
      homeAddress: {
        address: participant?.homeAddress,
        city: participant?.homeCity?.value,
        state: participant?.homeState?.value,
        country: participant?.homeCountry?.value,
        pin: participant?.homePin,
      },
      imageUrl: participant?.imageUrl,
      idProofUrl: participant?.idProofUrl,
    };
    if (!participants[index]?.schoolDetails) {
      participantData.schoolDetails = {
        name: participant?.schoolName || null,
        schoolId: participant?.schoolId || null,
        schoolAddress: {
          address: participant?.schoolAddress || null,
          state: participant?.schoolState?.value || null,
          city: participant?.schoolCity?.value || null,
          pin: participant?.schoolPin || null,
        },
      };
    }

    const id = participants[index]?.id;
    try {
      const participantRef = doc(db, "participants", id);
      console.log(id, participantRef);
      await updateDoc(participantRef, participantData);
    } catch (error) {
      throw error;
    }
  };

  const handleNext = (index) => {
    setError("");
    const participant = participantArray[index];
    const last = index === participantArray.length - 1;

    // check participant address
    if (!participant.homeAddress) {
      setError("Please enter home address");
      if (last) {
        throw error;
      }
      return;
    }
    if (!participant.homeState) {
      setError("Please enter home state");
      if (last) {
        throw error;
      }
      return;
    }
    if (!participant.homeCity) {
      setError("Please enter home city");
      if (last) {
        throw error;
      }
      return;
    }
    if (!participant.homePin) {
      setError("Please enter home pin");
      if (last) {
        throw error;
      }
      return;
    }
    if (participant.homePin?.length < 5) {
      setError("Please enter a valid pincode");
      if (last) {
        throw error;
      }
      return;
    }

    // check id proof
    if (!participant.idProofUrl) {
      setError("Please upload ID Proof");
      if (last) {
        throw error;
      }
      return;
    }

    // check participant image
    if (!participant.imageUrl) {
      setError("Please upload Participant Image");
      if (last) {
        throw error;
      }
      return;
    }
    if (participantArray.length - 1 !== index) {
      // scroll to top
      window.scrollTo({ top: 0, behavior: "smooth" });
      // move to next participant
      setActiveParticipantIndex((prev) => prev + 1);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      handleNext(participantArray.length - 1);
    } catch (error) {
      return console.log(error);
    }
    setLoading(true);
    participantArray.map(
      async (participant, index) =>
        await updateParticipantDetails(participant, index)
    );
    // clear local storage
    localStorage.removeItem("practido-step2-participantIds");
    navigate("/onboarding/success");
    setLoading(false);
  };

  return (
    <div className="container step3">
      {participantIds?.length > 0 || loading ? (
        <div className="">
          {referenceNumber && (
            <div className="payment-successful-card">
              <div className="col-md-8 center-card space-y-2">
                <img
                  className="banner-cloud"
                  src="/img/success-icon.png"
                  alt="successful"
                />
                <h4>Payment Successful</h4>
                <p className="!text-sm !font-thin">
                  <strong>Payment Id : {referenceNumber}</strong>
                </p>
                <p>
                  You have successfully registered {participants.length}{" "}
                  participants for the{" "}
                  <strong>Practido Online Olympiad 2024</strong> Please check{" "}
                  <span className="text-[#009DFF]">
                    {auth.currentUser.email}
                  </span>{" "}
                  for the details.
                </p>
              </div>
            </div>
          )}

          <div className="onboarding-second-step mt-5">
            <h3>
              Please fill the necessary information for registration{" "}
              <span>
                Ensure all personal details (name, class, school) and School
                details are correct during registration. These will appear on
                Certificates.
              </span>
            </h3>

            <div className="onboarding-form relative mt-16 w-full bg-[#FFE9C8] rounded-r-md rounded-b-md p-10">
              <div className="absolute -top-10 left-0 flex items-center ">
                {participantArray?.map((_, index) => (
                  <div
                    key={index}
                    className={`participant-count h-10 flex items-center justify-center px-3 rounded-t-xl border-[#FFE9C8] border-2
                        ${
                          activeParticipantIndex === index
                            ? "bg-[#FFE9C8]"
                            : "bg-white"
                        }
                    `}
                    // onClick={() => setActiveParticipantIndex(index)}
                  >
                    <div className="font-semibold">
                      <span>Participant</span> {index + 1}
                    </div>
                  </div>
                ))}
              </div>

              <form onSubmit={handleSubmit}>
                {participantArray?.map((participant, index) => (
                  <div
                    key={index}
                    className={index !== activeParticipantIndex ? "hidden" : ""}
                  >
                    <h1 className="text-lg font-bold mb-4 flex items-center gap-2">
                      <span>Participant Name : </span>
                      <span className="text-gray-700 capitalize">
                        {participants[index]?.name}
                      </span>
                    </h1>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Capture a Picture of the Participant
                            <div className="tooltip-box">
                              <img
                                src="/img/info-icon.png"
                                className="mx-1"
                                alt="info"
                              />
                              <span className="tooltiptext">
                                To keep the competition fair and prevent
                                cheating, we ask for a photo of your child. This
                                helps us verify their identity during the event
                                and ensures the same child who registered is
                                participating. Your cooperation in providing a
                                recent, clear picture is much appreciated. Thank
                                you!
                              </span>
                            </div>
                          </label>
                          {!participant?.imageUrl && !imageUploading ? (
                            <>
                              <div className="w-full h-full mb-3 d-none">
                                <img
                                  src="/img/instruction-ImgCapture.png"
                                  alt=""
                                />
                              </div>
                              <Button
                                className="take-photo"
                                version="black"
                                text="Take a Photo"
                                handleClick={() => {
                                  openImageCapture(
                                    activeParticipantIndex,
                                    "participantImage"
                                  );
                                }}
                              />
                            </>
                          ) : (
                            <div className="flex gap-7 items-center">
                              <div className="w-[250px] h-[150px] rounded-2xl overflow-hidden">
                                {imageUploading ? (
                                  <div className="w-full h-full relative flex items-center justify-center">
                                    <img
                                      src={uploadImage}
                                      alt="uploading"
                                      className="object-cover w-full h-full"
                                    />
                                    <div className="absolute w-full h-[3px] bottom-0 left-0">
                                      <div
                                        style={{
                                          width: `${imageUploadProgress}%`,
                                        }}
                                        className="h-full bg-red-700 transition-width duration-300 ease-in-out z-50"
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <img
                                    src={participant?.imageUrl}
                                    alt={`Participant ${index} Pic`}
                                    className="object-cover w-full h-full"
                                  />
                                )}
                              </div>

                              <div
                                className="font-bold text-[#34495E] flex gap-2 items-center cursor-pointer"
                                onClick={() => handleRetake(index)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={2}
                                  stroke="currentColor"
                                  className="w-5"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                                  />
                                </svg>
                                Retry
                              </div>
                              <div
                                className="font-bold text-[#FF1800] flex gap-2 items-center cursor-pointer"
                                onClick={() =>
                                  handleDelete("participantImage", index)
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={2}
                                  stroke="currentColor"
                                  className="w-5"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                  />
                                </svg>
                                Remove
                              </div>
                            </div>
                          )}
                          <div className="text-[#f00] text-xs mt-1 h-3">
                            {/* {errorArray[activeParticipantIndex]?.imageError} */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group flex flex-col">
                          <label>
                            Student’s Id Proof<sup>*</sup>
                          </label>
                          <FileUpload
                            updateUrl={updateUrl}
                            participantModuleIndex={activeParticipantIndex}
                            openImageCapture={openImageCapture}
                            uploadedImageUrl={
                              participantArray[activeParticipantIndex]
                                ?.idProofUrl
                            }
                            handleDelete={handleDelete}
                            updateRef={updateRef}
                            participantName={
                              participants[activeParticipantIndex]?.name
                            }
                            idProofUploading={idProofUploading}
                            imageUploadProgress={imageUploadProgress}
                          />
                        </div>
                      </div>
                    </div>

                    <h2 className="text-lg font-bold my-2">Home Details</h2>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
                      <div className="form-group select-wrapper">
                        <label>
                          Home Country<sup>*</sup>
                        </label>
                        <SelectInput
                          placeholderText="Select Home Country"
                          selectOptions={allCountries}
                          // error={errorArray[index].stateError}
                          value={participant?.homeCountry}
                          noOptionsMessage={() => "No Country Found"}
                          handleChange={(selectedOptions) => {
                            const fieldValue = selectedOptions;
                            handleFieldChange(fieldValue, index, "homeCountry");
                          }}
                          isRequired={true}
                        />
                      </div>

                      <div className="form-group select-wrapper">
                        <label>
                          Home State<sup>*</sup>
                        </label>
                        <SelectInput
                          placeholderText="Select Home State"
                          selectOptions={allStates(
                            participantArray[index]?.homeCountry?.id
                          )}
                          // error={errorArray[index].stateError}
                          value={participant?.homeState}
                          noOptionsMessage={() =>
                            participantArray[index].homeCountry
                              ? "Please Select Home Country First"
                              : "No State Found"
                          }
                          handleChange={(selectedOptions) => {
                            const fieldValue = selectedOptions;
                            handleFieldChange(fieldValue, index, "homeState");
                          }}
                          isRequired={true}
                        />
                      </div>

                      <div className="form-group select-wrapper">
                        <label>
                          Home City<sup>*</sup>
                        </label>
                        <SelectInput
                          placeholderText="Select Home City"
                          selectOptions={allCities(
                            participantArray[index]?.homeCountry?.id,
                            participantArray[index]?.homeState?.id
                          )}
                          // error={errorArray[activeParticipantIndex].cityError}
                          noOptionsMessage={() =>
                            participantArray[index].homeCountry === ""
                              ? "Please Select Home Country First"
                              : participantArray[index].homeState === ""
                              ? "Please Select Home State First"
                              : "No City Found"
                          }
                          handleChange={(selectedOption) => {
                            const fieldValue = selectedOption;
                            handleFieldChange(fieldValue, index, "homeCity");
                          }}
                          isRequired={true}
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Home Address<sup>*</sup>
                        </label>
                        <TextInput
                          type="text"
                          placeholderText="Home Address"
                          inputValue={participant?.homeAddress}
                          isRequired={true}
                          inputName="homeAddress"
                          handleChange={(e) => {
                            const fieldValue = e.target.value;
                            handleFieldChange(fieldValue, index, "homeAddress");
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Home Pincode<sup>*</sup>
                        </label>
                        <TextInput
                          type="number"
                          placeholderText="Home PinCode"
                          isRequired={true}
                          inputValue={participant?.homePin}
                          inputName="homePin"
                          handleChange={(e) => {
                            const fieldValue = e.target.value;
                            handleFieldChange(fieldValue, index, "homePin");
                          }}
                        />
                      </div>
                    </div>

                    {!participants[index]?.schoolDetails && (
                      <>
                        <h2 className="text-lg font-bold my-2">
                          School Details
                        </h2>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
                          <div className="form-group">
                            <label>School Name</label>
                            <TextInput
                              type="text"
                              placeholderText="School Name"
                              inputValue={participant?.schoolName}
                              inputName="schoolName"
                              handleChange={(e) => {
                                const fieldValue = e.target.value;
                                handleFieldChange(
                                  fieldValue,
                                  index,
                                  "schoolName"
                                );
                              }}
                            />
                          </div>

                          <div className="form-group">
                            <label>School Country</label>
                            <SelectInput
                              placeholderText="Select School Country"
                              selectOptions={allCountries}
                              // error={errorArray[index].stateError}
                              value={participant?.schoolCountry}
                              noOptionsMessage={() => "No Country Found"}
                              handleChange={(selectedOptions) => {
                                const fieldValue = selectedOptions;
                                handleFieldChange(
                                  fieldValue,
                                  index,
                                  "schoolCountry"
                                );
                              }}
                            />
                          </div>

                          <div className="form-group">
                            <label>School State</label>
                            <SelectInput
                              placeholderText="Select School State"
                              selectOptions={allStates(
                                participantArray[index]?.schoolCountry?.id
                              )}
                              // error={errorArray[index].stateError}
                              value={participant?.schoolState}
                              noOptionsMessage={() =>
                                participantArray[index].schoolCountry
                                  ? "Please Select School Country First"
                                  : "No State Found"
                              }
                              handleChange={(selectedOptions) => {
                                const fieldValue = selectedOptions;
                                handleFieldChange(
                                  fieldValue,
                                  index,
                                  "schoolState"
                                );
                              }}
                            />
                          </div>

                          <div className="form-group">
                            <label>School City</label>
                            <SelectInput
                              placeholderText="Select School City"
                              selectOptions={allCities(
                                participantArray[index]?.schoolCountry?.id,
                                participantArray[index]?.schoolState?.id
                              )}
                              // error={errorArray[activeParticipantIndex].cityError}
                              noOptionsMessage={() =>
                                participantArray[index].schoolcountry === ""
                                  ? "Please Select School Country First"
                                  : participantArray[index].schoolState === ""
                                  ? "Please Select School State First"
                                  : "No City Found"
                              }
                              handleChange={(selectedOption) => {
                                const fieldValue = selectedOption;
                                handleFieldChange(
                                  fieldValue,
                                  index,
                                  "schoolCity"
                                );
                              }}
                            />
                          </div>

                          <div className="form-group">
                            <label>School Address</label>
                            <TextInput
                              type="text"
                              placeholderText="School Address"
                              inputValue={participant?.schoolAddress}
                              inputName="schoolAddress"
                              handleChange={(e) => {
                                const fieldValue = e.target.value;
                                handleFieldChange(
                                  fieldValue,
                                  index,
                                  "schoolAddress"
                                );
                              }}
                            />
                          </div>

                          <div className="form-group">
                            <label>School Pincode</label>
                            <TextInput
                              type="number"
                              placeholderText="School PinCode"
                              inputValue={participant?.schoolPin}
                              inputName="schoolPin"
                              handleChange={(e) => {
                                const fieldValue = e.target.value;
                                handleFieldChange(
                                  fieldValue,
                                  index,
                                  "schoolPin"
                                );
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label>School Code</label>
                            <TextInput
                              type="text"
                              placeholderText="School Code"
                              inputValue={participant?.schoolCode}
                              inputName="schoolCode"
                              handleChange={(e) => {
                                const fieldValue = e.target.value;
                                handleFieldChange(
                                  fieldValue,
                                  index,
                                  "schoolCode"
                                );
                              }}
                            />
                          </div>
                        </div>
                      </>
                    )}

                    <div className="my-2">
                      <div className="checkbox-wrapper subscribe-check">
                        <div className="flex items-center">
                          <Checkbox
                            // checkId="Maths"
                            handleChange={() => setChecked(!checked)}
                            labelText=""
                          />
                          <label
                            htmlFor="acknowledge"
                            className="text-sm cursor-pointer pl-[5px] text-[#757575]"
                          >
                            Subscribe to receive our upcoming newsletters and
                            event announcements.
                          </label>
                        </div>
                      </div>
                      <div className="text-red-500 font-medium h-6 px-2">
                        {error}
                      </div>
                      {index + 1 < participantArray.length ? (
                        <Button
                          text="Next"
                          version="secondary"
                          handleClick={() => {
                            handleNext(index);
                          }}
                        />
                      ) : (
                        <div className="flex items-center gap-3">
                          {participantArray.length > 1 &&
                            activeParticipantIndex > 0 && (
                              <Button
                                text="Prev Participant"
                                version="secondary"
                                handleClick={() => {
                                  // scroll to top
                                  window.scrollTo({
                                    top: 0,
                                    behavior: "smooth",
                                  });
                                  setActiveParticipantIndex((prev) => prev - 1);
                                }}
                              />
                            )}
                          <Button
                            text="Submit"
                            version="secondary"
                            loading={loading}
                            type="submit"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </form>
            </div>
          </div>

          {imageCaptureModule && (
            <div className="capture-modal">
              <div className="inner-div flex w-full h-full bg-[#000]/20 backdrop-blur-sm fixed top-0 left-0 items-center justify-center">
                <ImageCapture
                  updateUrl={updateUrl}
                  setImageCaptureModule={setImageCaptureModule}
                  imageCaptureModule={imageCaptureModule}
                  participantModuleIndex={participantModuleIndex}
                  participantName={participants[participantModuleIndex]?.name}
                  updateRef={updateRef}
                  setUploadImage={setUploadImage}
                  setLoading={
                    imageCaptureModule === "idProof"
                      ? setIdProofUploading
                      : setImageUploading
                  }
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="w-full h-[10rem] text-xl font-bold text-[#666] flex flex-col gap-4 items-center justify-center">
          No participant to add details
          <Button
            version="black"
            text="← Add Participant"
            handleClick={() => navigate("/onboarding/step1")}
          />
        </div>
      )}
    </div>
  );
};

export default Step3;
