import React, { useEffect, useRef, useState } from "react";
import {
  schoolContactRegisterFormFields,
  schoolPrincipalRegisterFormFields,
  schoolRegisterFormFields,
} from "../../constants/schoolRegisterFormFields";
import { SelectInput, TextInput, Button } from "../../../../components";
import { Link, useNavigate } from "react-router-dom";
import { getCountryCallingCode } from "libphonenumber-js";
import {
  fetchSignInMethodsForEmail,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  updateProfile,
} from "firebase/auth";
import { auth, db } from "../../../../config/firebaseConfig";
import OtpModule from "../OtpModule";
import { doc, setDoc } from "firebase/firestore";
import UploadLogo from "./UploadLogo";
import { validatePhone } from "../../../../assets/js/validation";
import {
  allCities,
  allCountries,
  allStates,
} from "../../../../constant/countryStateCityList";

const SchoolRegisterForm = ({ setShowTabs }) => {
  const navigate = useNavigate();
  const formRef = useRef(null);
  const recaptchaRef = useRef(null);
  const [confirmationResult, setConfirmationResult] = useState(null);

  const [otpOpen, setOtpOpen] = useState(false);
  const [otpError, setOtpError] = useState("");

  const [designation, setDesignation] = useState("");
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState("");

  const [formDetails, setFormDetails] = useState(null);

  const [logoUrl, setLogoUrl] = useState("");
  const [logoRef, setLogoRef] = useState(null);

  const [address, setAddress] = useState({});

  useEffect(() => {
    if (otpOpen) setShowTabs(false);
    else setShowTabs(true);
  }, [otpOpen, setShowTabs]);

  const sendOtp = async (phone) => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        recaptchaRef.current,
        {
          size: "invisible",
        }
      );
    }
    window.recaptchaVerifier.render();

    // add countryPhoneCode with phone
    const countryPhoneCode = getCountryCallingCode(address?.country?.id);
    const phoneNumber = `+${countryPhoneCode}${phone}`;
    try {
      // Send OTP to phone number
      const result = await signInWithPhoneNumber(
        auth,
        phoneNumber,
        window.recaptchaVerifier
      );
      setConfirmationResult(result);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    const formData = e.target;
    setFormDetails(formData);
    const phone = formData.schoolPhone.value;
    const email = formData.schoolEmail.value;

    //validate Phones
    if (!validatePhone(phone)) {
      setError("Please enter a valid 10 digit school phone number");
      formData.schoolPhone.focus();
      return;
    }
    if (!validatePhone(formData.principalPhone.value)) {
      setError("Please enter a valid 10 digit principal phone number");
      formData.principalPhone.focus();
      return;
    }
    if (
      designation !== "principal" &&
      !validatePhone(formData.contactPersonPhone.value)
    ) {
      setError("Please enter a valid 10 digit contactPerson phone number");
      formData.contactPersonPhone.focus();
      return;
    }
    if (formData?.pincode?.value.length < 5) {
      setError("Please enter a valid pincode");
      formData.pincode.focus();
      return;
    }

    // validat email for auth already exists
    const emailExists = await fetchSignInMethodsForEmail(auth, email);
    if (emailExists.length) {
      setError("Email already in use, try another email Id.");
      formData.schoolEmail.focus();
      return;
    }

    try {
      setLoading((prev) => ({ ...prev, register: true }));
      await sendOtp(phone);
      setOtpOpen(true);
    } catch (error) {
      setError(error.code);
    } finally {
      setLoading((prev) => ({ ...prev, register: false }));
    }
  };
  const handleOtpSubmit = async (e, otp) => {
    e.preventDefault();
    setLoading((prev) => ({ ...prev, otpSubmit: true }));
    const countryPhoneCode = getCountryCallingCode(address?.country?.id);

    try {
      // 1. Confirm OTP
      try {
        await confirmationResult.confirm(otp);
      } catch (error) {
        console.log(error.code);
        setError("Please enter a valid OTP");
        return;
      }

      // 2. Update user profile with display name
      await updateProfile(auth.currentUser, {
        displayName: formDetails?.schoolName?.value,
        photoURL: logoUrl || null,
      });

      // 3. school data
      const designation = formDetails?.designation?.value;
      const uniqueCode = `${formDetails?.schoolName?.value
        .split(" ")
        .map((i) => i[0].toUpperCase())
        .slice(0, 3)
        .join("")}_${formDetails?.schoolPhone?.value?.slice(-4)}_${Math.random()
        .toString(36)
        .slice(-4)}`;
      const schoolData = {
        accountType: "school",
        principalName: formDetails?.principalName?.value,
        principalEmail: formDetails?.principalEmail?.value,
        principalPhone: countryPhoneCode + formDetails?.principalPhone?.value,
        contactPersonName:
          designation === "principal"
            ? formDetails?.principalName?.value
            : formDetails?.contactPersonName?.value,
        contactPersonEmail:
          designation === "principal"
            ? formDetails?.principalEmail?.value
            : formDetails?.contactPersonEmail?.value,
        contactPersonPhone:
          designation === "principal"
            ? countryPhoneCode + formDetails?.principalPhone?.value
            : countryPhoneCode + formDetails?.contactPersonPhone?.value,
        schoolName: formDetails?.schoolName?.value,
        schoolEmail: formDetails?.schoolEmail?.value,
        schoolPhone: countryPhoneCode + formDetails?.schoolPhone?.value,
        schoolAddress: {
          address: formDetails?.schoolAddress?.value,
          city: address?.city?.value,
          state: address?.state?.value,
          country: address?.country?.value,
          pin: formDetails?.pincode?.value,
        },
        schoolLogoUrl: logoUrl,
        uid: auth.currentUser.uid,
        uniqueCode: uniqueCode,
      };

      // 4. add data to fireStore
      await setDoc(doc(db, "schools", uniqueCode), schoolData);

      setOtpOpen(false);

      window.localStorage.setItem(
        "emailForSettingPassword",
        formDetails?.schoolEmail?.value
      );
      navigate("setPassword");
    } catch (error) {
      console.log(error);
      setOtpError(error.code);
    } finally {
      setLoading((prev) => ({ ...prev, otpSubmit: false }));
    }
  };

  return (
    <>
      {otpOpen ? (
        <OtpModule
          phone={formDetails?.schoolPhone?.value}
          handleSubmit={handleOtpSubmit}
          sendOtp={sendOtp}
          loading={loading?.otpSubmit}
          error={otpError}
          otpError={otpError}
        />
      ) : (
        <form onSubmit={handleSubmit} className="wid-100" ref={formRef}>
          <div className="form-outer-container">
            {designation === "principal" ? (
              <h2 className="box-heading">Principal Information</h2>
            ) : (
              <h2 className="box-heading">Contact Person Information</h2>
            )}
            <div className="form-group select-wrapper">
              <label htmlFor="designation">
                Designation <span> * </span>
              </label>
              <SelectInput
                placeholderText="Select Designation"
                selectOptions={[
                  { id: "principal", label: `Principal`, value: "principal" },
                  { id: "teacher", label: `Teacher`, value: "teacher" },
                ]}
                isRequired={true}
                inputName="designation"
                handleChange={(selected) => setDesignation(selected.value)}
              />
            </div>
            {designation === "principal"
              ? schoolPrincipalRegisterFormFields?.map(
                  ({ name, label, type, required, placeholder, colSpan }) => {
                    return (
                      <div
                        key={name}
                        className={`form-group col-span-${colSpan}`}
                      >
                        <label htmlFor={name}>
                          {label}
                          {required && <span> * </span>}
                        </label>

                        <TextInput
                          type={type}
                          placeholderText={placeholder}
                          inputName={name}
                          isRequired={required}
                          idName={name}
                        />
                      </div>
                    );
                  }
                )
              : schoolContactRegisterFormFields?.map(
                  ({ name, label, type, required, placeholder, colSpan }) => {
                    return (
                      <div
                        key={name}
                        className={`form-group col-span-${colSpan}`}
                      >
                        <label htmlFor={name}>
                          {label}
                          {required && <span> * </span>}
                        </label>

                        <TextInput
                          type={type}
                          placeholderText={placeholder}
                          inputName={name}
                          isRequired={required}
                          idName={name}
                        />
                      </div>
                    );
                  }
                )}
          </div>
          <div className="form-outer-container">
            <h2 className="box-heading">School Information</h2>
            <div className="row">
              {schoolRegisterFormFields?.map(
                ({ name, label, type, required, placeholder }) => {
                  return (
                    <div
                      key={name}
                      className={`form-group col-md-${
                        name === "country" ||
                        name === "state" ||
                        name === "city" ||
                        name === "pincode"
                          ? "6"
                          : 12
                      } ${
                        name === "country" ||
                        name === "state" ||
                        name === "city"
                          ? "select-wrapper"
                          : ""
                      }`}
                    >
                      <label htmlFor={name}>
                        {label}
                        {required && <span> * </span>}
                      </label>

                      {name === "country" ? (
                        <SelectInput
                          placeholderText="Select Country"
                          selectOptions={allCountries}
                          value={address?.country}
                          noOptionsMessage={() => "No State Found"}
                          handleChange={(selectedOptions) => {
                            const fieldValue = selectedOptions;
                            setAddress({ ...address, country: fieldValue });
                          }}
                        />
                      ) : name === "state" ? (
                        <SelectInput
                          placeholderText="Select State"
                          selectOptions={allStates(address?.country?.id)}
                          value={address?.state}
                          noOptionsMessage={() =>
                            !address?.country
                              ? "Please Select Country First"
                              : "No States Found"
                          }
                          handleChange={(selectedOptions) => {
                            const fieldValue = selectedOptions;
                            setAddress({ ...address, state: fieldValue });
                          }}
                          disabled={!address?.country}
                        />
                      ) : name === "city" ? (
                        <SelectInput
                          placeholderText="Select City"
                          selectOptions={allCities(
                            address?.country?.id,
                            address?.state?.id
                          )}
                          noOptionsMessage={() =>
                            !address?.country
                              ? "Please Select Country First"
                              : !address?.state
                              ? "Please Select State First"
                              : "No City Found"
                          }
                          handleChange={(selectedOption) => {
                            const fieldValue = selectedOption;
                            setAddress({ ...address, city: fieldValue });
                          }}
                          disabled={!address?.state}
                        />
                      ) : (
                        <TextInput
                          type={type}
                          placeholderText={placeholder}
                          inputName={name}
                          isRequired={required}
                          idName={name}
                        />
                      )}
                    </div>
                  );
                }
              )}
            </div>

            <div className="form-group ">
              <label htmlFor="schoolLogo">School Logo</label>
              <UploadLogo
                fileName={`school_logo/${"schoolName".replace(" ", "_")}_logo`}
                url={logoUrl}
                setUrl={setLogoUrl}
                fileRef={logoRef}
                setFileRef={setLogoRef}
                loading={loading}
                setLoading={setLoading}
              />
            </div>
          </div>
          {designation !== "principal" && (
            <div className="form-outer-container">
              <h2 className="box-heading">Principal Information</h2>
              {schoolPrincipalRegisterFormFields?.map(
                ({ name, label, type, required, placeholder, colSpan }) => {
                  return (
                    <div
                      key={name}
                      className={`form-group col-span-${colSpan}`}
                    >
                      <label htmlFor={name}>
                        {label}
                        {required && <span> * </span>}
                      </label>

                      <TextInput
                        type={type}
                        placeholderText={placeholder}
                        inputName={name}
                        isRequired={required}
                        idName={name}
                      />
                    </div>
                  );
                }
              )}
            </div>
          )}

          <div className="flex flex-col gap-4 mt-5 col-span-2">
            <div className="">
              <span className="error-txt text-red-500">{error}</span>
              <Button
                version="secondary"
                text="Submit"
                type="submit"
                isDisabled={loading?.logoUploading}
                loading={loading?.register}
              />
            </div>
            <div className="text-lg flex gap-2">
              <p className="font-medium">Already Have a Account?</p>
              <Link
                to="/login"
                className="font-bold hover:underline cursor-pointer text-[#009DFF] hover:text-[#009DFF]"
              >
                Sign In
              </Link>
            </div>
          </div>
        </form>
      )}
      <div ref={recaptchaRef} id="recaptcha-container" />
    </>
  );
};

export default SchoolRegisterForm;
